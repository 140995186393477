:root {
  --overlay-background: var(--color-ui-offwhite);
}

.overlay-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--overlay-background);
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  pointer-events: none;
}

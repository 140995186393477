[class*="aspect-"] {
  position: relative;
}

[class*="aspect-"] > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.aspect-3-2 {
  padding-bottom: calc(2 / 3 * 100%);
}

.aspect-1-1 {
  padding-bottom: calc(1 / 1 * 100%);
}

.aspect-16-9 {
  padding-bottom: calc(9 / 16 * 100%);
}

.aspect-4-3 {
  padding-bottom: calc(3 / 4 * 100%);
}

:root {
  --aspect-height: 1;
  --aspect-width: 1;
}

.aspect-custom {
  padding-bottom: calc(var(--aspect-height) / var(--aspect-width) * 100%);
}

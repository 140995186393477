.btn {
  min-width: 36px;
  padding: 9px 16px;
  color: var(--white);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-medium);
  text-align: center;
  width: auto;
  cursor: pointer;
  background-color: var(--gray-darkest);
  border: 1px solid var(--gray-dark);
  border-radius: 2px;
  -webkit-font-smoothing: antialiased;
  transition: 0.2s ease;
}
.btn.btn-pa-0 {
  padding: 0;
}
.btn:active,
.btn:hover,
.btn:focus,
.btn.btn-selected {
  border-color: var(--white);
}
.btn.btn-sm {
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn.btn-lg {
  padding-top: 17px;
  padding-bottom: 17px;
}
.btn.btn-black {
  background-color: var(--black);
}
.btn.btn-black:active,
.btn.btn-black:hover,
.btn.btn-selected.btn-black {
  background-color: var(--gray-dark);
}
.btn.btn-blue {
  border-color: var(--blue-hover);
  background-color: var(--blue);
}
.btn.btn-blue:active,
.btn.btn-blue:hover,
.btn.btn-blue.btn-selected {
  background-color: var(--blue-hover);
}
.btn.btn-white {
  color: var(--gray-darkest);
  background-color: var(--white);
  border: 1px solid var(--gray-light);
  -webkit-font-smoothing: auto;
  padding: 10px 17px;
}
.btn.btn-white.btn-lg {
  padding-top: 18px;
  padding-bottom: 18px;
}
.btn.btn-white.btn-sm {
  padding-top: 6px;
  padding-bottom: 6px;
}
.btn.btn-white:hover {
  border: 1px solid var(--gray-light);
}
.btn.btn-white:active,
.btn.btn-white:hover,
.btn.btn-selected.btn-white {
  background-color: var(--gray-lightest);
}
.btn.btn-alert {
  background-color: var(--red);
  border: none;
}
.btn.btn-alert:active,
.btn.btn-alert:hover,
.btn.btn-selected.btn-alert {
  background-color: var(--red-bright);
}
.btn.btn-disabled {
  box-shadow: none;
  color: var(--gray);
  cursor: default;
  -webkit-font-smoothing: auto;
}
.btn.btn-disabled,
.btn.btn-disabled:hover {
  background-color: var(--gray-light);
  border: none;
}
.btn.btn-disabled:active {
  background-color: var(--gray-light);
  border: 2px solid transparent;
}
.btn.btn-ghost {
  background-color: transparent;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  padding: 10px 18px;
  box-shadow: none;
}
.btn.btn-ghost:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
  border: 1px solid transparent;
}
.btn.btn-ghost:active,
.btn.btn-selected.btn-ghost {
  background-color: hsla(0, 0%, 100%, 0.2);
  border: 2px solid hsla(0, 0%, 100%, 0.15);
  padding: 9px 17px;
}
.btn.btn-sm.btn-ghost {
  padding-top: 6px;
  padding-bottom: 6px;
}
.btn.btn-lg.btn-ghost {
  padding-top: 18px;
  padding-bottom: 18px;
}
.btn.btn-sm.btn-ghost:active,
.btn.btn-sm.btn-ghost.btn-selected {
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn.btn-lg.btn-ghost:active,
.btn.btn-lg.btn-ghost.btn-selected {
  padding-top: 17px;
  padding-bottom: 17px;
}

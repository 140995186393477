/* Autogenerated by css/build/build-spacers.js */

.ma-0 {
  margin: 0px;
}

@media only screen and (min-width: 768px) {
  .ma-0-ns {
    margin: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-0-m {
    margin: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-0-l {
    margin: 0px;
  }
}

.mt-0 {
  margin-top: 0px;
}

@media only screen and (min-width: 768px) {
  .mt-0-ns {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-0-m {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-0-l {
    margin-top: 0px;
  }
}

.mr-0 {
  margin-right: 0px;
}

@media only screen and (min-width: 768px) {
  .mr-0-ns {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-0-m {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-0-l {
    margin-right: 0px;
  }
}

.mb-0 {
  margin-bottom: 0px;
}

@media only screen and (min-width: 768px) {
  .mb-0-ns {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-0-m {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-0-l {
    margin-bottom: 0px;
  }
}

.ml-0 {
  margin-left: 0px;
}

@media only screen and (min-width: 768px) {
  .ml-0-ns {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-0-m {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-0-l {
    margin-left: 0px;
  }
}

.pa-0 {
  padding: 0px;
}

@media only screen and (min-width: 768px) {
  .pa-0-ns {
    padding: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-0-m {
    padding: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-0-l {
    padding: 0px;
  }
}

.pt-0 {
  padding-top: 0px;
}

@media only screen and (min-width: 768px) {
  .pt-0-ns {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-0-m {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-0-l {
    padding-top: 0px;
  }
}

.pr-0 {
  padding-right: 0px;
}

@media only screen and (min-width: 768px) {
  .pr-0-ns {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-0-m {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-0-l {
    padding-right: 0px;
  }
}

.pb-0 {
  padding-bottom: 0px;
}

@media only screen and (min-width: 768px) {
  .pb-0-ns {
    padding-bottom: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-0-m {
    padding-bottom: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-0-l {
    padding-bottom: 0px;
  }
}

.pl-0 {
  padding-left: 0px;
}

@media only screen and (min-width: 768px) {
  .pl-0-ns {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-0-m {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-0-l {
    padding-left: 0px;
  }
}

.-ma-0 {
  margin: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-ma-0-ns {
    margin: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-0-m {
    margin: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-0-l {
    margin: calc(-1 * 0px);
  }
}

.-mt-0 {
  margin-top: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-mt-0-ns {
    margin-top: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-0-m {
    margin-top: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-0-l {
    margin-top: calc(-1 * 0px);
  }
}

.-mr-0 {
  margin-right: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-mr-0-ns {
    margin-right: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-0-m {
    margin-right: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-0-l {
    margin-right: calc(-1 * 0px);
  }
}

.-mb-0 {
  margin-bottom: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-mb-0-ns {
    margin-bottom: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-0-m {
    margin-bottom: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-0-l {
    margin-bottom: calc(-1 * 0px);
  }
}

.-ml-0 {
  margin-left: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-ml-0-ns {
    margin-left: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-0-m {
    margin-left: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-0-l {
    margin-left: calc(-1 * 0px);
  }
}

.-pa-0 {
  padding: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-pa-0-ns {
    padding: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-0-m {
    padding: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-0-l {
    padding: calc(-1 * 0px);
  }
}

.-pt-0 {
  padding-top: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-pt-0-ns {
    padding-top: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-0-m {
    padding-top: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-0-l {
    padding-top: calc(-1 * 0px);
  }
}

.-pr-0 {
  padding-right: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-pr-0-ns {
    padding-right: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-0-m {
    padding-right: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-0-l {
    padding-right: calc(-1 * 0px);
  }
}

.-pb-0 {
  padding-bottom: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-pb-0-ns {
    padding-bottom: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-0-m {
    padding-bottom: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-0-l {
    padding-bottom: calc(-1 * 0px);
  }
}

.-pl-0 {
  padding-left: calc(-1 * 0px);
}

@media only screen and (min-width: 768px) {
  .-pl-0-ns {
    padding-left: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-0-m {
    padding-left: calc(-1 * 0px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-0-l {
    padding-left: calc(-1 * 0px);
  }
}

.ma-auto {
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .ma-auto-ns {
    margin: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-auto-m {
    margin: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-auto-l {
    margin: auto;
  }
}

.mt-auto {
  margin-top: auto;
}

@media only screen and (min-width: 768px) {
  .mt-auto-ns {
    margin-top: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-auto-m {
    margin-top: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-auto-l {
    margin-top: auto;
  }
}

.mr-auto {
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .mr-auto-ns {
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-auto-m {
    margin-right: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-auto-l {
    margin-right: auto;
  }
}

.mb-auto {
  margin-bottom: auto;
}

@media only screen and (min-width: 768px) {
  .mb-auto-ns {
    margin-bottom: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-auto-m {
    margin-bottom: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-auto-l {
    margin-bottom: auto;
  }
}

.ml-auto {
  margin-left: auto;
}

@media only screen and (min-width: 768px) {
  .ml-auto-ns {
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-auto-m {
    margin-left: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-auto-l {
    margin-left: auto;
  }
}

.pa-auto {
  padding: auto;
}

@media only screen and (min-width: 768px) {
  .pa-auto-ns {
    padding: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-auto-m {
    padding: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-auto-l {
    padding: auto;
  }
}

.pt-auto {
  padding-top: auto;
}

@media only screen and (min-width: 768px) {
  .pt-auto-ns {
    padding-top: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-auto-m {
    padding-top: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-auto-l {
    padding-top: auto;
  }
}

.pr-auto {
  padding-right: auto;
}

@media only screen and (min-width: 768px) {
  .pr-auto-ns {
    padding-right: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-auto-m {
    padding-right: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-auto-l {
    padding-right: auto;
  }
}

.pb-auto {
  padding-bottom: auto;
}

@media only screen and (min-width: 768px) {
  .pb-auto-ns {
    padding-bottom: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-auto-m {
    padding-bottom: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-auto-l {
    padding-bottom: auto;
  }
}

.pl-auto {
  padding-left: auto;
}

@media only screen and (min-width: 768px) {
  .pl-auto-ns {
    padding-left: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-auto-m {
    padding-left: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-auto-l {
    padding-left: auto;
  }
}

.ma-xxs {
  margin: 4px;
}

@media only screen and (min-width: 768px) {
  .ma-xxs-ns {
    margin: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-xxs-m {
    margin: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-xxs-l {
    margin: 4px;
  }
}

.mt-xxs {
  margin-top: 4px;
}

@media only screen and (min-width: 768px) {
  .mt-xxs-ns {
    margin-top: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-xxs-m {
    margin-top: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-xxs-l {
    margin-top: 4px;
  }
}

.mr-xxs {
  margin-right: 4px;
}

@media only screen and (min-width: 768px) {
  .mr-xxs-ns {
    margin-right: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-xxs-m {
    margin-right: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-xxs-l {
    margin-right: 4px;
  }
}

.mb-xxs {
  margin-bottom: 4px;
}

@media only screen and (min-width: 768px) {
  .mb-xxs-ns {
    margin-bottom: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-xxs-m {
    margin-bottom: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-xxs-l {
    margin-bottom: 4px;
  }
}

.ml-xxs {
  margin-left: 4px;
}

@media only screen and (min-width: 768px) {
  .ml-xxs-ns {
    margin-left: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-xxs-m {
    margin-left: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-xxs-l {
    margin-left: 4px;
  }
}

.pa-xxs {
  padding: 4px;
}

@media only screen and (min-width: 768px) {
  .pa-xxs-ns {
    padding: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-xxs-m {
    padding: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-xxs-l {
    padding: 4px;
  }
}

.pt-xxs {
  padding-top: 4px;
}

@media only screen and (min-width: 768px) {
  .pt-xxs-ns {
    padding-top: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-xxs-m {
    padding-top: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-xxs-l {
    padding-top: 4px;
  }
}

.pr-xxs {
  padding-right: 4px;
}

@media only screen and (min-width: 768px) {
  .pr-xxs-ns {
    padding-right: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-xxs-m {
    padding-right: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-xxs-l {
    padding-right: 4px;
  }
}

.pb-xxs {
  padding-bottom: 4px;
}

@media only screen and (min-width: 768px) {
  .pb-xxs-ns {
    padding-bottom: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-xxs-m {
    padding-bottom: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-xxs-l {
    padding-bottom: 4px;
  }
}

.pl-xxs {
  padding-left: 4px;
}

@media only screen and (min-width: 768px) {
  .pl-xxs-ns {
    padding-left: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-xxs-m {
    padding-left: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-xxs-l {
    padding-left: 4px;
  }
}

.-ma-xxs {
  margin: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-ma-xxs-ns {
    margin: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-xxs-m {
    margin: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-xxs-l {
    margin: calc(-1 * 4px);
  }
}

.-mt-xxs {
  margin-top: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-mt-xxs-ns {
    margin-top: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-xxs-m {
    margin-top: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-xxs-l {
    margin-top: calc(-1 * 4px);
  }
}

.-mr-xxs {
  margin-right: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-mr-xxs-ns {
    margin-right: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-xxs-m {
    margin-right: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-xxs-l {
    margin-right: calc(-1 * 4px);
  }
}

.-mb-xxs {
  margin-bottom: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-mb-xxs-ns {
    margin-bottom: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-xxs-m {
    margin-bottom: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-xxs-l {
    margin-bottom: calc(-1 * 4px);
  }
}

.-ml-xxs {
  margin-left: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-ml-xxs-ns {
    margin-left: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-xxs-m {
    margin-left: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-xxs-l {
    margin-left: calc(-1 * 4px);
  }
}

.-pa-xxs {
  padding: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-pa-xxs-ns {
    padding: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-xxs-m {
    padding: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-xxs-l {
    padding: calc(-1 * 4px);
  }
}

.-pt-xxs {
  padding-top: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-pt-xxs-ns {
    padding-top: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-xxs-m {
    padding-top: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-xxs-l {
    padding-top: calc(-1 * 4px);
  }
}

.-pr-xxs {
  padding-right: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-pr-xxs-ns {
    padding-right: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-xxs-m {
    padding-right: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-xxs-l {
    padding-right: calc(-1 * 4px);
  }
}

.-pb-xxs {
  padding-bottom: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-pb-xxs-ns {
    padding-bottom: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-xxs-m {
    padding-bottom: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-xxs-l {
    padding-bottom: calc(-1 * 4px);
  }
}

.-pl-xxs {
  padding-left: calc(-1 * 4px);
}

@media only screen and (min-width: 768px) {
  .-pl-xxs-ns {
    padding-left: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-xxs-m {
    padding-left: calc(-1 * 4px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-xxs-l {
    padding-left: calc(-1 * 4px);
  }
}

.ma-xs {
  margin: 8px;
}

@media only screen and (min-width: 768px) {
  .ma-xs-ns {
    margin: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-xs-m {
    margin: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-xs-l {
    margin: 8px;
  }
}

.mt-xs {
  margin-top: 8px;
}

@media only screen and (min-width: 768px) {
  .mt-xs-ns {
    margin-top: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-xs-m {
    margin-top: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-xs-l {
    margin-top: 8px;
  }
}

.mr-xs {
  margin-right: 8px;
}

@media only screen and (min-width: 768px) {
  .mr-xs-ns {
    margin-right: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-xs-m {
    margin-right: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-xs-l {
    margin-right: 8px;
  }
}

.mb-xs {
  margin-bottom: 8px;
}

@media only screen and (min-width: 768px) {
  .mb-xs-ns {
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-xs-m {
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-xs-l {
    margin-bottom: 8px;
  }
}

.ml-xs {
  margin-left: 8px;
}

@media only screen and (min-width: 768px) {
  .ml-xs-ns {
    margin-left: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-xs-m {
    margin-left: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-xs-l {
    margin-left: 8px;
  }
}

.pa-xs {
  padding: 8px;
}

@media only screen and (min-width: 768px) {
  .pa-xs-ns {
    padding: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-xs-m {
    padding: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-xs-l {
    padding: 8px;
  }
}

.pt-xs {
  padding-top: 8px;
}

@media only screen and (min-width: 768px) {
  .pt-xs-ns {
    padding-top: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-xs-m {
    padding-top: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-xs-l {
    padding-top: 8px;
  }
}

.pr-xs {
  padding-right: 8px;
}

@media only screen and (min-width: 768px) {
  .pr-xs-ns {
    padding-right: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-xs-m {
    padding-right: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-xs-l {
    padding-right: 8px;
  }
}

.pb-xs {
  padding-bottom: 8px;
}

@media only screen and (min-width: 768px) {
  .pb-xs-ns {
    padding-bottom: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-xs-m {
    padding-bottom: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-xs-l {
    padding-bottom: 8px;
  }
}

.pl-xs {
  padding-left: 8px;
}

@media only screen and (min-width: 768px) {
  .pl-xs-ns {
    padding-left: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-xs-m {
    padding-left: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-xs-l {
    padding-left: 8px;
  }
}

.-ma-xs {
  margin: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-ma-xs-ns {
    margin: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-xs-m {
    margin: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-xs-l {
    margin: calc(-1 * 8px);
  }
}

.-mt-xs {
  margin-top: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-mt-xs-ns {
    margin-top: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-xs-m {
    margin-top: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-xs-l {
    margin-top: calc(-1 * 8px);
  }
}

.-mr-xs {
  margin-right: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-mr-xs-ns {
    margin-right: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-xs-m {
    margin-right: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-xs-l {
    margin-right: calc(-1 * 8px);
  }
}

.-mb-xs {
  margin-bottom: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-mb-xs-ns {
    margin-bottom: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-xs-m {
    margin-bottom: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-xs-l {
    margin-bottom: calc(-1 * 8px);
  }
}

.-ml-xs {
  margin-left: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-ml-xs-ns {
    margin-left: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-xs-m {
    margin-left: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-xs-l {
    margin-left: calc(-1 * 8px);
  }
}

.-pa-xs {
  padding: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-pa-xs-ns {
    padding: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-xs-m {
    padding: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-xs-l {
    padding: calc(-1 * 8px);
  }
}

.-pt-xs {
  padding-top: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-pt-xs-ns {
    padding-top: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-xs-m {
    padding-top: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-xs-l {
    padding-top: calc(-1 * 8px);
  }
}

.-pr-xs {
  padding-right: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-pr-xs-ns {
    padding-right: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-xs-m {
    padding-right: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-xs-l {
    padding-right: calc(-1 * 8px);
  }
}

.-pb-xs {
  padding-bottom: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-pb-xs-ns {
    padding-bottom: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-xs-m {
    padding-bottom: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-xs-l {
    padding-bottom: calc(-1 * 8px);
  }
}

.-pl-xs {
  padding-left: calc(-1 * 8px);
}

@media only screen and (min-width: 768px) {
  .-pl-xs-ns {
    padding-left: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-xs-m {
    padding-left: calc(-1 * 8px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-xs-l {
    padding-left: calc(-1 * 8px);
  }
}

.ma-sm {
  margin: 16px;
}

@media only screen and (min-width: 768px) {
  .ma-sm-ns {
    margin: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-sm-m {
    margin: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-sm-l {
    margin: 16px;
  }
}

.mt-sm {
  margin-top: 16px;
}

@media only screen and (min-width: 768px) {
  .mt-sm-ns {
    margin-top: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-sm-m {
    margin-top: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-sm-l {
    margin-top: 16px;
  }
}

.mr-sm {
  margin-right: 16px;
}

@media only screen and (min-width: 768px) {
  .mr-sm-ns {
    margin-right: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-sm-m {
    margin-right: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-sm-l {
    margin-right: 16px;
  }
}

.mb-sm {
  margin-bottom: 16px;
}

@media only screen and (min-width: 768px) {
  .mb-sm-ns {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-sm-m {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-sm-l {
    margin-bottom: 16px;
  }
}

.ml-sm {
  margin-left: 16px;
}

@media only screen and (min-width: 768px) {
  .ml-sm-ns {
    margin-left: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-sm-m {
    margin-left: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-sm-l {
    margin-left: 16px;
  }
}

.pa-sm {
  padding: 16px;
}

@media only screen and (min-width: 768px) {
  .pa-sm-ns {
    padding: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-sm-m {
    padding: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-sm-l {
    padding: 16px;
  }
}

.pt-sm {
  padding-top: 16px;
}

@media only screen and (min-width: 768px) {
  .pt-sm-ns {
    padding-top: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-sm-m {
    padding-top: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-sm-l {
    padding-top: 16px;
  }
}

.pr-sm {
  padding-right: 16px;
}

@media only screen and (min-width: 768px) {
  .pr-sm-ns {
    padding-right: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-sm-m {
    padding-right: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-sm-l {
    padding-right: 16px;
  }
}

.pb-sm {
  padding-bottom: 16px;
}

@media only screen and (min-width: 768px) {
  .pb-sm-ns {
    padding-bottom: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-sm-m {
    padding-bottom: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-sm-l {
    padding-bottom: 16px;
  }
}

.pl-sm {
  padding-left: 16px;
}

@media only screen and (min-width: 768px) {
  .pl-sm-ns {
    padding-left: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-sm-m {
    padding-left: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-sm-l {
    padding-left: 16px;
  }
}

.-ma-sm {
  margin: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-ma-sm-ns {
    margin: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-sm-m {
    margin: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-sm-l {
    margin: calc(-1 * 16px);
  }
}

.-mt-sm {
  margin-top: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-mt-sm-ns {
    margin-top: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-sm-m {
    margin-top: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-sm-l {
    margin-top: calc(-1 * 16px);
  }
}

.-mr-sm {
  margin-right: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-mr-sm-ns {
    margin-right: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-sm-m {
    margin-right: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-sm-l {
    margin-right: calc(-1 * 16px);
  }
}

.-mb-sm {
  margin-bottom: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-mb-sm-ns {
    margin-bottom: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-sm-m {
    margin-bottom: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-sm-l {
    margin-bottom: calc(-1 * 16px);
  }
}

.-ml-sm {
  margin-left: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-ml-sm-ns {
    margin-left: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-sm-m {
    margin-left: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-sm-l {
    margin-left: calc(-1 * 16px);
  }
}

.-pa-sm {
  padding: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-pa-sm-ns {
    padding: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-sm-m {
    padding: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-sm-l {
    padding: calc(-1 * 16px);
  }
}

.-pt-sm {
  padding-top: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-pt-sm-ns {
    padding-top: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-sm-m {
    padding-top: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-sm-l {
    padding-top: calc(-1 * 16px);
  }
}

.-pr-sm {
  padding-right: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-pr-sm-ns {
    padding-right: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-sm-m {
    padding-right: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-sm-l {
    padding-right: calc(-1 * 16px);
  }
}

.-pb-sm {
  padding-bottom: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-pb-sm-ns {
    padding-bottom: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-sm-m {
    padding-bottom: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-sm-l {
    padding-bottom: calc(-1 * 16px);
  }
}

.-pl-sm {
  padding-left: calc(-1 * 16px);
}

@media only screen and (min-width: 768px) {
  .-pl-sm-ns {
    padding-left: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-sm-m {
    padding-left: calc(-1 * 16px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-sm-l {
    padding-left: calc(-1 * 16px);
  }
}

.ma-md {
  margin: 24px;
}

@media only screen and (min-width: 768px) {
  .ma-md-ns {
    margin: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-md-m {
    margin: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-md-l {
    margin: 24px;
  }
}

.mt-md {
  margin-top: 24px;
}

@media only screen and (min-width: 768px) {
  .mt-md-ns {
    margin-top: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-md-m {
    margin-top: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-md-l {
    margin-top: 24px;
  }
}

.mr-md {
  margin-right: 24px;
}

@media only screen and (min-width: 768px) {
  .mr-md-ns {
    margin-right: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-md-m {
    margin-right: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-md-l {
    margin-right: 24px;
  }
}

.mb-md {
  margin-bottom: 24px;
}

@media only screen and (min-width: 768px) {
  .mb-md-ns {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-md-m {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-md-l {
    margin-bottom: 24px;
  }
}

.ml-md {
  margin-left: 24px;
}

@media only screen and (min-width: 768px) {
  .ml-md-ns {
    margin-left: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-md-m {
    margin-left: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-md-l {
    margin-left: 24px;
  }
}

.pa-md {
  padding: 24px;
}

@media only screen and (min-width: 768px) {
  .pa-md-ns {
    padding: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-md-m {
    padding: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-md-l {
    padding: 24px;
  }
}

.pt-md {
  padding-top: 24px;
}

@media only screen and (min-width: 768px) {
  .pt-md-ns {
    padding-top: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-md-m {
    padding-top: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-md-l {
    padding-top: 24px;
  }
}

.pr-md {
  padding-right: 24px;
}

@media only screen and (min-width: 768px) {
  .pr-md-ns {
    padding-right: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-md-m {
    padding-right: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-md-l {
    padding-right: 24px;
  }
}

.pb-md {
  padding-bottom: 24px;
}

@media only screen and (min-width: 768px) {
  .pb-md-ns {
    padding-bottom: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-md-m {
    padding-bottom: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-md-l {
    padding-bottom: 24px;
  }
}

.pl-md {
  padding-left: 24px;
}

@media only screen and (min-width: 768px) {
  .pl-md-ns {
    padding-left: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-md-m {
    padding-left: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-md-l {
    padding-left: 24px;
  }
}

.-ma-md {
  margin: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-ma-md-ns {
    margin: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-md-m {
    margin: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-md-l {
    margin: calc(-1 * 24px);
  }
}

.-mt-md {
  margin-top: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-mt-md-ns {
    margin-top: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-md-m {
    margin-top: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-md-l {
    margin-top: calc(-1 * 24px);
  }
}

.-mr-md {
  margin-right: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-mr-md-ns {
    margin-right: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-md-m {
    margin-right: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-md-l {
    margin-right: calc(-1 * 24px);
  }
}

.-mb-md {
  margin-bottom: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-mb-md-ns {
    margin-bottom: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-md-m {
    margin-bottom: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-md-l {
    margin-bottom: calc(-1 * 24px);
  }
}

.-ml-md {
  margin-left: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-ml-md-ns {
    margin-left: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-md-m {
    margin-left: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-md-l {
    margin-left: calc(-1 * 24px);
  }
}

.-pa-md {
  padding: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-pa-md-ns {
    padding: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-md-m {
    padding: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-md-l {
    padding: calc(-1 * 24px);
  }
}

.-pt-md {
  padding-top: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-pt-md-ns {
    padding-top: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-md-m {
    padding-top: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-md-l {
    padding-top: calc(-1 * 24px);
  }
}

.-pr-md {
  padding-right: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-pr-md-ns {
    padding-right: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-md-m {
    padding-right: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-md-l {
    padding-right: calc(-1 * 24px);
  }
}

.-pb-md {
  padding-bottom: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-pb-md-ns {
    padding-bottom: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-md-m {
    padding-bottom: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-md-l {
    padding-bottom: calc(-1 * 24px);
  }
}

.-pl-md {
  padding-left: calc(-1 * 24px);
}

@media only screen and (min-width: 768px) {
  .-pl-md-ns {
    padding-left: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-md-m {
    padding-left: calc(-1 * 24px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-md-l {
    padding-left: calc(-1 * 24px);
  }
}

.ma-lg {
  margin: 32px;
}

@media only screen and (min-width: 768px) {
  .ma-lg-ns {
    margin: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-lg-m {
    margin: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-lg-l {
    margin: 32px;
  }
}

.mt-lg {
  margin-top: 32px;
}

@media only screen and (min-width: 768px) {
  .mt-lg-ns {
    margin-top: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-lg-m {
    margin-top: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-lg-l {
    margin-top: 32px;
  }
}

.mr-lg {
  margin-right: 32px;
}

@media only screen and (min-width: 768px) {
  .mr-lg-ns {
    margin-right: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-lg-m {
    margin-right: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-lg-l {
    margin-right: 32px;
  }
}

.mb-lg {
  margin-bottom: 32px;
}

@media only screen and (min-width: 768px) {
  .mb-lg-ns {
    margin-bottom: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-lg-m {
    margin-bottom: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-lg-l {
    margin-bottom: 32px;
  }
}

.ml-lg {
  margin-left: 32px;
}

@media only screen and (min-width: 768px) {
  .ml-lg-ns {
    margin-left: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-lg-m {
    margin-left: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-lg-l {
    margin-left: 32px;
  }
}

.pa-lg {
  padding: 32px;
}

@media only screen and (min-width: 768px) {
  .pa-lg-ns {
    padding: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-lg-m {
    padding: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-lg-l {
    padding: 32px;
  }
}

.pt-lg {
  padding-top: 32px;
}

@media only screen and (min-width: 768px) {
  .pt-lg-ns {
    padding-top: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-lg-m {
    padding-top: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-lg-l {
    padding-top: 32px;
  }
}

.pr-lg {
  padding-right: 32px;
}

@media only screen and (min-width: 768px) {
  .pr-lg-ns {
    padding-right: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-lg-m {
    padding-right: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-lg-l {
    padding-right: 32px;
  }
}

.pb-lg {
  padding-bottom: 32px;
}

@media only screen and (min-width: 768px) {
  .pb-lg-ns {
    padding-bottom: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-lg-m {
    padding-bottom: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-lg-l {
    padding-bottom: 32px;
  }
}

.pl-lg {
  padding-left: 32px;
}

@media only screen and (min-width: 768px) {
  .pl-lg-ns {
    padding-left: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-lg-m {
    padding-left: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-lg-l {
    padding-left: 32px;
  }
}

.-ma-lg {
  margin: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-ma-lg-ns {
    margin: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-lg-m {
    margin: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-lg-l {
    margin: calc(-1 * 32px);
  }
}

.-mt-lg {
  margin-top: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-mt-lg-ns {
    margin-top: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-lg-m {
    margin-top: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-lg-l {
    margin-top: calc(-1 * 32px);
  }
}

.-mr-lg {
  margin-right: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-mr-lg-ns {
    margin-right: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-lg-m {
    margin-right: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-lg-l {
    margin-right: calc(-1 * 32px);
  }
}

.-mb-lg {
  margin-bottom: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-mb-lg-ns {
    margin-bottom: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-lg-m {
    margin-bottom: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-lg-l {
    margin-bottom: calc(-1 * 32px);
  }
}

.-ml-lg {
  margin-left: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-ml-lg-ns {
    margin-left: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-lg-m {
    margin-left: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-lg-l {
    margin-left: calc(-1 * 32px);
  }
}

.-pa-lg {
  padding: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-pa-lg-ns {
    padding: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-lg-m {
    padding: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-lg-l {
    padding: calc(-1 * 32px);
  }
}

.-pt-lg {
  padding-top: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-pt-lg-ns {
    padding-top: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-lg-m {
    padding-top: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-lg-l {
    padding-top: calc(-1 * 32px);
  }
}

.-pr-lg {
  padding-right: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-pr-lg-ns {
    padding-right: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-lg-m {
    padding-right: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-lg-l {
    padding-right: calc(-1 * 32px);
  }
}

.-pb-lg {
  padding-bottom: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-pb-lg-ns {
    padding-bottom: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-lg-m {
    padding-bottom: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-lg-l {
    padding-bottom: calc(-1 * 32px);
  }
}

.-pl-lg {
  padding-left: calc(-1 * 32px);
}

@media only screen and (min-width: 768px) {
  .-pl-lg-ns {
    padding-left: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-lg-m {
    padding-left: calc(-1 * 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-lg-l {
    padding-left: calc(-1 * 32px);
  }
}

.ma-lg-mod {
  margin: 40px;
}

@media only screen and (min-width: 768px) {
  .ma-lg-mod-ns {
    margin: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-lg-mod-m {
    margin: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-lg-mod-l {
    margin: 40px;
  }
}

.mt-lg-mod {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .mt-lg-mod-ns {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-lg-mod-m {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-lg-mod-l {
    margin-top: 40px;
  }
}

.mr-lg-mod {
  margin-right: 40px;
}

@media only screen and (min-width: 768px) {
  .mr-lg-mod-ns {
    margin-right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-lg-mod-m {
    margin-right: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-lg-mod-l {
    margin-right: 40px;
  }
}

.mb-lg-mod {
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .mb-lg-mod-ns {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-lg-mod-m {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-lg-mod-l {
    margin-bottom: 40px;
  }
}

.ml-lg-mod {
  margin-left: 40px;
}

@media only screen and (min-width: 768px) {
  .ml-lg-mod-ns {
    margin-left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-lg-mod-m {
    margin-left: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-lg-mod-l {
    margin-left: 40px;
  }
}

.pa-lg-mod {
  padding: 40px;
}

@media only screen and (min-width: 768px) {
  .pa-lg-mod-ns {
    padding: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-lg-mod-m {
    padding: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-lg-mod-l {
    padding: 40px;
  }
}

.pt-lg-mod {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) {
  .pt-lg-mod-ns {
    padding-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-lg-mod-m {
    padding-top: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-lg-mod-l {
    padding-top: 40px;
  }
}

.pr-lg-mod {
  padding-right: 40px;
}

@media only screen and (min-width: 768px) {
  .pr-lg-mod-ns {
    padding-right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-lg-mod-m {
    padding-right: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-lg-mod-l {
    padding-right: 40px;
  }
}

.pb-lg-mod {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .pb-lg-mod-ns {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-lg-mod-m {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-lg-mod-l {
    padding-bottom: 40px;
  }
}

.pl-lg-mod {
  padding-left: 40px;
}

@media only screen and (min-width: 768px) {
  .pl-lg-mod-ns {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-lg-mod-m {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-lg-mod-l {
    padding-left: 40px;
  }
}

.-ma-lg-mod {
  margin: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-ma-lg-mod-ns {
    margin: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-lg-mod-m {
    margin: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-lg-mod-l {
    margin: calc(-1 * 40px);
  }
}

.-mt-lg-mod {
  margin-top: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-mt-lg-mod-ns {
    margin-top: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-lg-mod-m {
    margin-top: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-lg-mod-l {
    margin-top: calc(-1 * 40px);
  }
}

.-mr-lg-mod {
  margin-right: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-mr-lg-mod-ns {
    margin-right: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-lg-mod-m {
    margin-right: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-lg-mod-l {
    margin-right: calc(-1 * 40px);
  }
}

.-mb-lg-mod {
  margin-bottom: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-mb-lg-mod-ns {
    margin-bottom: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-lg-mod-m {
    margin-bottom: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-lg-mod-l {
    margin-bottom: calc(-1 * 40px);
  }
}

.-ml-lg-mod {
  margin-left: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-ml-lg-mod-ns {
    margin-left: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-lg-mod-m {
    margin-left: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-lg-mod-l {
    margin-left: calc(-1 * 40px);
  }
}

.-pa-lg-mod {
  padding: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-pa-lg-mod-ns {
    padding: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-lg-mod-m {
    padding: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-lg-mod-l {
    padding: calc(-1 * 40px);
  }
}

.-pt-lg-mod {
  padding-top: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-pt-lg-mod-ns {
    padding-top: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-lg-mod-m {
    padding-top: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-lg-mod-l {
    padding-top: calc(-1 * 40px);
  }
}

.-pr-lg-mod {
  padding-right: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-pr-lg-mod-ns {
    padding-right: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-lg-mod-m {
    padding-right: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-lg-mod-l {
    padding-right: calc(-1 * 40px);
  }
}

.-pb-lg-mod {
  padding-bottom: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-pb-lg-mod-ns {
    padding-bottom: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-lg-mod-m {
    padding-bottom: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-lg-mod-l {
    padding-bottom: calc(-1 * 40px);
  }
}

.-pl-lg-mod {
  padding-left: calc(-1 * 40px);
}

@media only screen and (min-width: 768px) {
  .-pl-lg-mod-ns {
    padding-left: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-lg-mod-m {
    padding-left: calc(-1 * 40px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-lg-mod-l {
    padding-left: calc(-1 * 40px);
  }
}

.ma-xl {
  margin: 48px;
}

@media only screen and (min-width: 768px) {
  .ma-xl-ns {
    margin: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-xl-m {
    margin: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-xl-l {
    margin: 48px;
  }
}

.mt-xl {
  margin-top: 48px;
}

@media only screen and (min-width: 768px) {
  .mt-xl-ns {
    margin-top: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-xl-m {
    margin-top: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-xl-l {
    margin-top: 48px;
  }
}

.mr-xl {
  margin-right: 48px;
}

@media only screen and (min-width: 768px) {
  .mr-xl-ns {
    margin-right: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-xl-m {
    margin-right: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-xl-l {
    margin-right: 48px;
  }
}

.mb-xl {
  margin-bottom: 48px;
}

@media only screen and (min-width: 768px) {
  .mb-xl-ns {
    margin-bottom: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-xl-m {
    margin-bottom: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-xl-l {
    margin-bottom: 48px;
  }
}

.ml-xl {
  margin-left: 48px;
}

@media only screen and (min-width: 768px) {
  .ml-xl-ns {
    margin-left: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-xl-m {
    margin-left: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-xl-l {
    margin-left: 48px;
  }
}

.pa-xl {
  padding: 48px;
}

@media only screen and (min-width: 768px) {
  .pa-xl-ns {
    padding: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-xl-m {
    padding: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-xl-l {
    padding: 48px;
  }
}

.pt-xl {
  padding-top: 48px;
}

@media only screen and (min-width: 768px) {
  .pt-xl-ns {
    padding-top: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-xl-m {
    padding-top: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-xl-l {
    padding-top: 48px;
  }
}

.pr-xl {
  padding-right: 48px;
}

@media only screen and (min-width: 768px) {
  .pr-xl-ns {
    padding-right: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-xl-m {
    padding-right: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-xl-l {
    padding-right: 48px;
  }
}

.pb-xl {
  padding-bottom: 48px;
}

@media only screen and (min-width: 768px) {
  .pb-xl-ns {
    padding-bottom: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-xl-m {
    padding-bottom: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-xl-l {
    padding-bottom: 48px;
  }
}

.pl-xl {
  padding-left: 48px;
}

@media only screen and (min-width: 768px) {
  .pl-xl-ns {
    padding-left: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-xl-m {
    padding-left: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-xl-l {
    padding-left: 48px;
  }
}

.-ma-xl {
  margin: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-ma-xl-ns {
    margin: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-xl-m {
    margin: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-xl-l {
    margin: calc(-1 * 48px);
  }
}

.-mt-xl {
  margin-top: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-mt-xl-ns {
    margin-top: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-xl-m {
    margin-top: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-xl-l {
    margin-top: calc(-1 * 48px);
  }
}

.-mr-xl {
  margin-right: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-mr-xl-ns {
    margin-right: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-xl-m {
    margin-right: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-xl-l {
    margin-right: calc(-1 * 48px);
  }
}

.-mb-xl {
  margin-bottom: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-mb-xl-ns {
    margin-bottom: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-xl-m {
    margin-bottom: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-xl-l {
    margin-bottom: calc(-1 * 48px);
  }
}

.-ml-xl {
  margin-left: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-ml-xl-ns {
    margin-left: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-xl-m {
    margin-left: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-xl-l {
    margin-left: calc(-1 * 48px);
  }
}

.-pa-xl {
  padding: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-pa-xl-ns {
    padding: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-xl-m {
    padding: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-xl-l {
    padding: calc(-1 * 48px);
  }
}

.-pt-xl {
  padding-top: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-pt-xl-ns {
    padding-top: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-xl-m {
    padding-top: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-xl-l {
    padding-top: calc(-1 * 48px);
  }
}

.-pr-xl {
  padding-right: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-pr-xl-ns {
    padding-right: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-xl-m {
    padding-right: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-xl-l {
    padding-right: calc(-1 * 48px);
  }
}

.-pb-xl {
  padding-bottom: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-pb-xl-ns {
    padding-bottom: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-xl-m {
    padding-bottom: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-xl-l {
    padding-bottom: calc(-1 * 48px);
  }
}

.-pl-xl {
  padding-left: calc(-1 * 48px);
}

@media only screen and (min-width: 768px) {
  .-pl-xl-ns {
    padding-left: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-xl-m {
    padding-left: calc(-1 * 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-xl-l {
    padding-left: calc(-1 * 48px);
  }
}

.ma-xxl {
  margin: 64px;
}

@media only screen and (min-width: 768px) {
  .ma-xxl-ns {
    margin: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-xxl-m {
    margin: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-xxl-l {
    margin: 64px;
  }
}

.mt-xxl {
  margin-top: 64px;
}

@media only screen and (min-width: 768px) {
  .mt-xxl-ns {
    margin-top: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-xxl-m {
    margin-top: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-xxl-l {
    margin-top: 64px;
  }
}

.mr-xxl {
  margin-right: 64px;
}

@media only screen and (min-width: 768px) {
  .mr-xxl-ns {
    margin-right: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-xxl-m {
    margin-right: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-xxl-l {
    margin-right: 64px;
  }
}

.mb-xxl {
  margin-bottom: 64px;
}

@media only screen and (min-width: 768px) {
  .mb-xxl-ns {
    margin-bottom: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-xxl-m {
    margin-bottom: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-xxl-l {
    margin-bottom: 64px;
  }
}

.ml-xxl {
  margin-left: 64px;
}

@media only screen and (min-width: 768px) {
  .ml-xxl-ns {
    margin-left: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-xxl-m {
    margin-left: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-xxl-l {
    margin-left: 64px;
  }
}

.pa-xxl {
  padding: 64px;
}

@media only screen and (min-width: 768px) {
  .pa-xxl-ns {
    padding: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-xxl-m {
    padding: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-xxl-l {
    padding: 64px;
  }
}

.pt-xxl {
  padding-top: 64px;
}

@media only screen and (min-width: 768px) {
  .pt-xxl-ns {
    padding-top: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-xxl-m {
    padding-top: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-xxl-l {
    padding-top: 64px;
  }
}

.pr-xxl {
  padding-right: 64px;
}

@media only screen and (min-width: 768px) {
  .pr-xxl-ns {
    padding-right: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-xxl-m {
    padding-right: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-xxl-l {
    padding-right: 64px;
  }
}

.pb-xxl {
  padding-bottom: 64px;
}

@media only screen and (min-width: 768px) {
  .pb-xxl-ns {
    padding-bottom: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-xxl-m {
    padding-bottom: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-xxl-l {
    padding-bottom: 64px;
  }
}

.pl-xxl {
  padding-left: 64px;
}

@media only screen and (min-width: 768px) {
  .pl-xxl-ns {
    padding-left: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-xxl-m {
    padding-left: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-xxl-l {
    padding-left: 64px;
  }
}

.-ma-xxl {
  margin: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-ma-xxl-ns {
    margin: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-xxl-m {
    margin: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-xxl-l {
    margin: calc(-1 * 64px);
  }
}

.-mt-xxl {
  margin-top: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-mt-xxl-ns {
    margin-top: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-xxl-m {
    margin-top: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-xxl-l {
    margin-top: calc(-1 * 64px);
  }
}

.-mr-xxl {
  margin-right: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-mr-xxl-ns {
    margin-right: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-xxl-m {
    margin-right: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-xxl-l {
    margin-right: calc(-1 * 64px);
  }
}

.-mb-xxl {
  margin-bottom: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-mb-xxl-ns {
    margin-bottom: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-xxl-m {
    margin-bottom: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-xxl-l {
    margin-bottom: calc(-1 * 64px);
  }
}

.-ml-xxl {
  margin-left: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-ml-xxl-ns {
    margin-left: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-xxl-m {
    margin-left: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-xxl-l {
    margin-left: calc(-1 * 64px);
  }
}

.-pa-xxl {
  padding: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-pa-xxl-ns {
    padding: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-xxl-m {
    padding: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-xxl-l {
    padding: calc(-1 * 64px);
  }
}

.-pt-xxl {
  padding-top: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-pt-xxl-ns {
    padding-top: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-xxl-m {
    padding-top: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-xxl-l {
    padding-top: calc(-1 * 64px);
  }
}

.-pr-xxl {
  padding-right: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-pr-xxl-ns {
    padding-right: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-xxl-m {
    padding-right: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-xxl-l {
    padding-right: calc(-1 * 64px);
  }
}

.-pb-xxl {
  padding-bottom: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-pb-xxl-ns {
    padding-bottom: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-xxl-m {
    padding-bottom: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-xxl-l {
    padding-bottom: calc(-1 * 64px);
  }
}

.-pl-xxl {
  padding-left: calc(-1 * 64px);
}

@media only screen and (min-width: 768px) {
  .-pl-xxl-ns {
    padding-left: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-xxl-m {
    padding-left: calc(-1 * 64px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-xxl-l {
    padding-left: calc(-1 * 64px);
  }
}

.ma-xxl-mod {
  margin: 80px;
}

@media only screen and (min-width: 768px) {
  .ma-xxl-mod-ns {
    margin: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ma-xxl-mod-m {
    margin: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .ma-xxl-mod-l {
    margin: 80px;
  }
}

.mt-xxl-mod {
  margin-top: 80px;
}

@media only screen and (min-width: 768px) {
  .mt-xxl-mod-ns {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mt-xxl-mod-m {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .mt-xxl-mod-l {
    margin-top: 80px;
  }
}

.mr-xxl-mod {
  margin-right: 80px;
}

@media only screen and (min-width: 768px) {
  .mr-xxl-mod-ns {
    margin-right: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mr-xxl-mod-m {
    margin-right: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .mr-xxl-mod-l {
    margin-right: 80px;
  }
}

.mb-xxl-mod {
  margin-bottom: 80px;
}

@media only screen and (min-width: 768px) {
  .mb-xxl-mod-ns {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mb-xxl-mod-m {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-xxl-mod-l {
    margin-bottom: 80px;
  }
}

.ml-xxl-mod {
  margin-left: 80px;
}

@media only screen and (min-width: 768px) {
  .ml-xxl-mod-ns {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ml-xxl-mod-m {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .ml-xxl-mod-l {
    margin-left: 80px;
  }
}

.pa-xxl-mod {
  padding: 80px;
}

@media only screen and (min-width: 768px) {
  .pa-xxl-mod-ns {
    padding: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pa-xxl-mod-m {
    padding: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .pa-xxl-mod-l {
    padding: 80px;
  }
}

.pt-xxl-mod {
  padding-top: 80px;
}

@media only screen and (min-width: 768px) {
  .pt-xxl-mod-ns {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pt-xxl-mod-m {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .pt-xxl-mod-l {
    padding-top: 80px;
  }
}

.pr-xxl-mod {
  padding-right: 80px;
}

@media only screen and (min-width: 768px) {
  .pr-xxl-mod-ns {
    padding-right: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-xxl-mod-m {
    padding-right: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .pr-xxl-mod-l {
    padding-right: 80px;
  }
}

.pb-xxl-mod {
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) {
  .pb-xxl-mod-ns {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pb-xxl-mod-m {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .pb-xxl-mod-l {
    padding-bottom: 80px;
  }
}

.pl-xxl-mod {
  padding-left: 80px;
}

@media only screen and (min-width: 768px) {
  .pl-xxl-mod-ns {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pl-xxl-mod-m {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .pl-xxl-mod-l {
    padding-left: 80px;
  }
}

.-ma-xxl-mod {
  margin: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-ma-xxl-mod-ns {
    margin: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ma-xxl-mod-m {
    margin: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ma-xxl-mod-l {
    margin: calc(-1 * 80px);
  }
}

.-mt-xxl-mod {
  margin-top: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-mt-xxl-mod-ns {
    margin-top: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mt-xxl-mod-m {
    margin-top: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mt-xxl-mod-l {
    margin-top: calc(-1 * 80px);
  }
}

.-mr-xxl-mod {
  margin-right: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-mr-xxl-mod-ns {
    margin-right: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mr-xxl-mod-m {
    margin-right: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mr-xxl-mod-l {
    margin-right: calc(-1 * 80px);
  }
}

.-mb-xxl-mod {
  margin-bottom: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-mb-xxl-mod-ns {
    margin-bottom: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-mb-xxl-mod-m {
    margin-bottom: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-mb-xxl-mod-l {
    margin-bottom: calc(-1 * 80px);
  }
}

.-ml-xxl-mod {
  margin-left: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-ml-xxl-mod-ns {
    margin-left: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-ml-xxl-mod-m {
    margin-left: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-ml-xxl-mod-l {
    margin-left: calc(-1 * 80px);
  }
}

.-pa-xxl-mod {
  padding: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-pa-xxl-mod-ns {
    padding: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pa-xxl-mod-m {
    padding: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pa-xxl-mod-l {
    padding: calc(-1 * 80px);
  }
}

.-pt-xxl-mod {
  padding-top: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-pt-xxl-mod-ns {
    padding-top: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pt-xxl-mod-m {
    padding-top: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pt-xxl-mod-l {
    padding-top: calc(-1 * 80px);
  }
}

.-pr-xxl-mod {
  padding-right: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-pr-xxl-mod-ns {
    padding-right: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pr-xxl-mod-m {
    padding-right: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pr-xxl-mod-l {
    padding-right: calc(-1 * 80px);
  }
}

.-pb-xxl-mod {
  padding-bottom: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-pb-xxl-mod-ns {
    padding-bottom: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pb-xxl-mod-m {
    padding-bottom: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pb-xxl-mod-l {
    padding-bottom: calc(-1 * 80px);
  }
}

.-pl-xxl-mod {
  padding-left: calc(-1 * 80px);
}

@media only screen and (min-width: 768px) {
  .-pl-xxl-mod-ns {
    padding-left: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .-pl-xxl-mod-m {
    padding-left: calc(-1 * 80px);
  }
}

@media only screen and (min-width: 1024px) {
  .-pl-xxl-mod-l {
    padding-left: calc(-1 * 80px);
  }
}

/* prettier-ignore */
/* Autogenerated by build/tachyons.js */

/* our traditional brand color */
.bg-blue {
  background-color: var(--wpds-colors-blue80);
}

.blue {
  color: var(--wpds-colors-blue80);
}

.bc-blue {
  border-color: var(--wpds-colors-blue80);
}

.fill-blue {
  fill: var(--wpds-colors-blue80);
}

.hover-fill-blue:hover {
  fill: var(--wpds-colors-blue80);
}

.hover-blue:hover {
  color: var(--wpds-colors-blue80);
}

.hover-bg-blue:hover {
  background-color: var(--wpds-colors-blue80);
}

.focus-bg-blue:focus {
  background-color: var(--wpds-colors-blue80);
}

.focus-bc-blue:focus {
  border-color: var(--wpds-colors-blue80);
}


.bg-blue-dark {
  background-color: var(--wpds-colors-blue40);
}

.blue-dark {
  color: var(--wpds-colors-blue40);
}

.bc-blue-dark {
  border-color: var(--wpds-colors-blue40);
}

.fill-blue-dark {
  fill: var(--wpds-colors-blue40);
}

.hover-fill-blue-dark:hover {
  fill: var(--wpds-colors-blue40);
}

.hover-blue-dark:hover {
  color: var(--wpds-colors-blue40);
}

.hover-bg-blue-dark:hover {
  background-color: var(--wpds-colors-blue40);
}

.focus-bg-blue-dark:focus {
  background-color: var(--wpds-colors-blue40);
}

.focus-bc-blue-dark:focus {
  border-color: var(--wpds-colors-blue40);
}


.bg-blue-bright {
  background-color: var(--wpds-colors-blue200);
}

.blue-bright {
  color: var(--wpds-colors-blue200);
}

.bc-blue-bright {
  border-color: var(--wpds-colors-blue200);
}

.fill-blue-bright {
  fill: var(--wpds-colors-blue200);
}

.hover-fill-blue-bright:hover {
  fill: var(--wpds-colors-blue200);
}

.hover-blue-bright:hover {
  color: var(--wpds-colors-blue200);
}

.hover-bg-blue-bright:hover {
  background-color: var(--wpds-colors-blue200);
}

.focus-bg-blue-bright:focus {
  background-color: var(--wpds-colors-blue200);
}

.focus-bc-blue-bright:focus {
  border-color: var(--wpds-colors-blue200);
}


.bg-blue-pale {
  background-color: var(--wpds-colors-blue600);
}

.blue-pale {
  color: var(--wpds-colors-blue600);
}

.bc-blue-pale {
  border-color: var(--wpds-colors-blue600);
}

.fill-blue-pale {
  fill: var(--wpds-colors-blue600);
}

.hover-fill-blue-pale:hover {
  fill: var(--wpds-colors-blue600);
}

.hover-blue-pale:hover {
  color: var(--wpds-colors-blue600);
}

.hover-bg-blue-pale:hover {
  background-color: var(--wpds-colors-blue600);
}

.focus-bg-blue-pale:focus {
  background-color: var(--wpds-colors-blue600);
}

.focus-bc-blue-pale:focus {
  border-color: var(--wpds-colors-blue600);
}


.bg-white {
  background-color: var(--wpds-colors-gray700);
}

.white {
  color: var(--wpds-colors-gray700);
}

.bc-white {
  border-color: var(--wpds-colors-gray700);
}

.fill-white {
  fill: var(--wpds-colors-gray700);
}

.hover-fill-white:hover {
  fill: var(--wpds-colors-gray700);
}

.hover-white:hover {
  color: var(--wpds-colors-gray700);
}

.hover-bg-white:hover {
  background-color: var(--wpds-colors-gray700);
}

.focus-bg-white:focus {
  background-color: var(--wpds-colors-gray700);
}

.focus-bc-white:focus {
  border-color: var(--wpds-colors-gray700);
}


.bg-offwhite {
  background-color: var(--wpds-colors-gray600);
}

.offwhite {
  color: var(--wpds-colors-gray600);
}

.bc-offwhite {
  border-color: var(--wpds-colors-gray600);
}

.fill-offwhite {
  fill: var(--wpds-colors-gray600);
}

.hover-fill-offwhite:hover {
  fill: var(--wpds-colors-gray600);
}

.hover-offwhite:hover {
  color: var(--wpds-colors-gray600);
}

.hover-bg-offwhite:hover {
  background-color: var(--wpds-colors-gray600);
}

.focus-bg-offwhite:focus {
  background-color: var(--wpds-colors-gray600);
}

.focus-bc-offwhite:focus {
  border-color: var(--wpds-colors-gray600);
}


.bg-black {
  background-color: var(--wpds-colors-gray0);
}

.black {
  color: var(--wpds-colors-gray0);
}

.bc-black {
  border-color: var(--wpds-colors-gray0);
}

.fill-black {
  fill: var(--wpds-colors-gray0);
}

.hover-fill-black:hover {
  fill: var(--wpds-colors-gray0);
}

.hover-black:hover {
  color: var(--wpds-colors-gray0);
}

.hover-bg-black:hover {
  background-color: var(--wpds-colors-gray0);
}

.focus-bg-black:focus {
  background-color: var(--wpds-colors-gray0);
}

.focus-bc-black:focus {
  border-color: var(--wpds-colors-gray0);
}


.bg-offblack {
  background-color: var(--wpds-colors-gray20);
}

.offblack {
  color: var(--wpds-colors-gray20);
}

.bc-offblack {
  border-color: var(--wpds-colors-gray20);
}

.fill-offblack {
  fill: var(--wpds-colors-gray20);
}

.hover-fill-offblack:hover {
  fill: var(--wpds-colors-gray20);
}

.hover-offblack:hover {
  color: var(--wpds-colors-gray20);
}

.hover-bg-offblack:hover {
  background-color: var(--wpds-colors-gray20);
}

.focus-bg-offblack:focus {
  background-color: var(--wpds-colors-gray20);
}

.focus-bc-offblack:focus {
  border-color: var(--wpds-colors-gray20);
}


.bg-gray-lightest {
  background-color: var(--wpds-colors-gray500);
}

.gray-lightest {
  color: var(--wpds-colors-gray500);
}

.bc-gray-lightest {
  border-color: var(--wpds-colors-gray500);
}

.fill-gray-lightest {
  fill: var(--wpds-colors-gray500);
}

.hover-fill-gray-lightest:hover {
  fill: var(--wpds-colors-gray500);
}

.hover-gray-lightest:hover {
  color: var(--wpds-colors-gray500);
}

.hover-bg-gray-lightest:hover {
  background-color: var(--wpds-colors-gray500);
}

.focus-bg-gray-lightest:focus {
  background-color: var(--wpds-colors-gray500);
}

.focus-bc-gray-lightest:focus {
  border-color: var(--wpds-colors-gray500);
}


.bg-gray-lighter {
  background-color: var(--wpds-colors-gray400);
}

.gray-lighter {
  color: var(--wpds-colors-gray400);
}

.bc-gray-lighter {
  border-color: var(--wpds-colors-gray400);
}

.fill-gray-lighter {
  fill: var(--wpds-colors-gray400);
}

.hover-fill-gray-lighter:hover {
  fill: var(--wpds-colors-gray400);
}

.hover-gray-lighter:hover {
  color: var(--wpds-colors-gray400);
}

.hover-bg-gray-lighter:hover {
  background-color: var(--wpds-colors-gray400);
}

.focus-bg-gray-lighter:focus {
  background-color: var(--wpds-colors-gray400);
}

.focus-bc-gray-lighter:focus {
  border-color: var(--wpds-colors-gray400);
}


.bg-gray-light {
  background-color: var(--wpds-colors-gray300);
}

.gray-light {
  color: var(--wpds-colors-gray300);
}

.bc-gray-light {
  border-color: var(--wpds-colors-gray300);
}

.fill-gray-light {
  fill: var(--wpds-colors-gray300);
}

.hover-fill-gray-light:hover {
  fill: var(--wpds-colors-gray300);
}

.hover-gray-light:hover {
  color: var(--wpds-colors-gray300);
}

.hover-bg-gray-light:hover {
  background-color: var(--wpds-colors-gray300);
}

.focus-bg-gray-light:focus {
  background-color: var(--wpds-colors-gray300);
}

.focus-bc-gray-light:focus {
  border-color: var(--wpds-colors-gray300);
}


.bg-gray {
  background-color: var(--wpds-colors-gray200);
}

.gray {
  color: var(--wpds-colors-gray200);
}

.bc-gray {
  border-color: var(--wpds-colors-gray200);
}

.fill-gray {
  fill: var(--wpds-colors-gray200);
}

.hover-fill-gray:hover {
  fill: var(--wpds-colors-gray200);
}

.hover-gray:hover {
  color: var(--wpds-colors-gray200);
}

.hover-bg-gray:hover {
  background-color: var(--wpds-colors-gray200);
}

.focus-bg-gray:focus {
  background-color: var(--wpds-colors-gray200);
}

.focus-bc-gray:focus {
  border-color: var(--wpds-colors-gray200);
}


.bg-gray-dark {
  background-color: var(--wpds-colors-gray80);
}

.gray-dark {
  color: var(--wpds-colors-gray80);
}

.bc-gray-dark {
  border-color: var(--wpds-colors-gray80);
}

.fill-gray-dark {
  fill: var(--wpds-colors-gray80);
}

.hover-fill-gray-dark:hover {
  fill: var(--wpds-colors-gray80);
}

.hover-gray-dark:hover {
  color: var(--wpds-colors-gray80);
}

.hover-bg-gray-dark:hover {
  background-color: var(--wpds-colors-gray80);
}

.focus-bg-gray-dark:focus {
  background-color: var(--wpds-colors-gray80);
}

.focus-bc-gray-dark:focus {
  border-color: var(--wpds-colors-gray80);
}


.bg-gray-darker {
  background-color: var(--wpds-colors-gray60);
}

.gray-darker {
  color: var(--wpds-colors-gray60);
}

.bc-gray-darker {
  border-color: var(--wpds-colors-gray60);
}

.fill-gray-darker {
  fill: var(--wpds-colors-gray60);
}

.hover-fill-gray-darker:hover {
  fill: var(--wpds-colors-gray60);
}

.hover-gray-darker:hover {
  color: var(--wpds-colors-gray60);
}

.hover-bg-gray-darker:hover {
  background-color: var(--wpds-colors-gray60);
}

.focus-bg-gray-darker:focus {
  background-color: var(--wpds-colors-gray60);
}

.focus-bc-gray-darker:focus {
  border-color: var(--wpds-colors-gray60);
}


.bg-gray-darkest {
  background-color: var(--wpds-colors-gray40);
}

.gray-darkest {
  color: var(--wpds-colors-gray40);
}

.bc-gray-darkest {
  border-color: var(--wpds-colors-gray40);
}

.fill-gray-darkest {
  fill: var(--wpds-colors-gray40);
}

.hover-fill-gray-darkest:hover {
  fill: var(--wpds-colors-gray40);
}

.hover-gray-darkest:hover {
  color: var(--wpds-colors-gray40);
}

.hover-bg-gray-darkest:hover {
  background-color: var(--wpds-colors-gray40);
}

.focus-bg-gray-darkest:focus {
  background-color: var(--wpds-colors-gray40);
}

.focus-bc-gray-darkest:focus {
  border-color: var(--wpds-colors-gray40);
}


.bg-red {
  background-color: var(--wpds-colors-red100);
}

.red {
  color: var(--wpds-colors-red100);
}

.bc-red {
  border-color: var(--wpds-colors-red100);
}

.fill-red {
  fill: var(--wpds-colors-red100);
}

.hover-fill-red:hover {
  fill: var(--wpds-colors-red100);
}

.hover-red:hover {
  color: var(--wpds-colors-red100);
}

.hover-bg-red:hover {
  background-color: var(--wpds-colors-red100);
}

.focus-bg-red:focus {
  background-color: var(--wpds-colors-red100);
}

.focus-bc-red:focus {
  border-color: var(--wpds-colors-red100);
}


.bg-red-dark {
  background-color: var(--wpds-colors-red80);
}

.red-dark {
  color: var(--wpds-colors-red80);
}

.bc-red-dark {
  border-color: var(--wpds-colors-red80);
}

.fill-red-dark {
  fill: var(--wpds-colors-red80);
}

.hover-fill-red-dark:hover {
  fill: var(--wpds-colors-red80);
}

.hover-red-dark:hover {
  color: var(--wpds-colors-red80);
}

.hover-bg-red-dark:hover {
  background-color: var(--wpds-colors-red80);
}

.focus-bg-red-dark:focus {
  background-color: var(--wpds-colors-red80);
}

.focus-bc-red-dark:focus {
  border-color: var(--wpds-colors-red80);
}


.bg-red-bright {
  background-color: var(--wpds-colors-red300);
}

.red-bright {
  color: var(--wpds-colors-red300);
}

.bc-red-bright {
  border-color: var(--wpds-colors-red300);
}

.fill-red-bright {
  fill: var(--wpds-colors-red300);
}

.hover-fill-red-bright:hover {
  fill: var(--wpds-colors-red300);
}

.hover-red-bright:hover {
  color: var(--wpds-colors-red300);
}

.hover-bg-red-bright:hover {
  background-color: var(--wpds-colors-red300);
}

.focus-bg-red-bright:focus {
  background-color: var(--wpds-colors-red300);
}

.focus-bc-red-bright:focus {
  border-color: var(--wpds-colors-red300);
}


.bg-red-pale {
  background-color: var(--wpds-colors-red600);
}

.red-pale {
  color: var(--wpds-colors-red600);
}

.bc-red-pale {
  border-color: var(--wpds-colors-red600);
}

.fill-red-pale {
  fill: var(--wpds-colors-red600);
}

.hover-fill-red-pale:hover {
  fill: var(--wpds-colors-red600);
}

.hover-red-pale:hover {
  color: var(--wpds-colors-red600);
}

.hover-bg-red-pale:hover {
  background-color: var(--wpds-colors-red600);
}

.focus-bg-red-pale:focus {
  background-color: var(--wpds-colors-red600);
}

.focus-bc-red-pale:focus {
  border-color: var(--wpds-colors-red600);
}


.bg-orange {
  background-color: var(--wpds-colors-orange100);
}

.orange {
  color: var(--wpds-colors-orange100);
}

.bc-orange {
  border-color: var(--wpds-colors-orange100);
}

.fill-orange {
  fill: var(--wpds-colors-orange100);
}

.hover-fill-orange:hover {
  fill: var(--wpds-colors-orange100);
}

.hover-orange:hover {
  color: var(--wpds-colors-orange100);
}

.hover-bg-orange:hover {
  background-color: var(--wpds-colors-orange100);
}

.focus-bg-orange:focus {
  background-color: var(--wpds-colors-orange100);
}

.focus-bc-orange:focus {
  border-color: var(--wpds-colors-orange100);
}


.bg-orange-dark {
  background-color: var(--wpds-colors-orange80);
}

.orange-dark {
  color: var(--wpds-colors-orange80);
}

.bc-orange-dark {
  border-color: var(--wpds-colors-orange80);
}

.fill-orange-dark {
  fill: var(--wpds-colors-orange80);
}

.hover-fill-orange-dark:hover {
  fill: var(--wpds-colors-orange80);
}

.hover-orange-dark:hover {
  color: var(--wpds-colors-orange80);
}

.hover-bg-orange-dark:hover {
  background-color: var(--wpds-colors-orange80);
}

.focus-bg-orange-dark:focus {
  background-color: var(--wpds-colors-orange80);
}

.focus-bc-orange-dark:focus {
  border-color: var(--wpds-colors-orange80);
}


.bg-orange-bright {
  background-color: var(--wpds-colors-orange300);
}

.orange-bright {
  color: var(--wpds-colors-orange300);
}

.bc-orange-bright {
  border-color: var(--wpds-colors-orange300);
}

.fill-orange-bright {
  fill: var(--wpds-colors-orange300);
}

.hover-fill-orange-bright:hover {
  fill: var(--wpds-colors-orange300);
}

.hover-orange-bright:hover {
  color: var(--wpds-colors-orange300);
}

.hover-bg-orange-bright:hover {
  background-color: var(--wpds-colors-orange300);
}

.focus-bg-orange-bright:focus {
  background-color: var(--wpds-colors-orange300);
}

.focus-bc-orange-bright:focus {
  border-color: var(--wpds-colors-orange300);
}


.bg-orange-pale {
  background-color: var(--wpds-colors-orange600);
}

.orange-pale {
  color: var(--wpds-colors-orange600);
}

.bc-orange-pale {
  border-color: var(--wpds-colors-orange600);
}

.fill-orange-pale {
  fill: var(--wpds-colors-orange600);
}

.hover-fill-orange-pale:hover {
  fill: var(--wpds-colors-orange600);
}

.hover-orange-pale:hover {
  color: var(--wpds-colors-orange600);
}

.hover-bg-orange-pale:hover {
  background-color: var(--wpds-colors-orange600);
}

.focus-bg-orange-pale:focus {
  background-color: var(--wpds-colors-orange600);
}

.focus-bc-orange-pale:focus {
  border-color: var(--wpds-colors-orange600);
}


.bg-green {
  background-color: var(--wpds-colors-green100);
}

.green {
  color: var(--wpds-colors-green100);
}

.bc-green {
  border-color: var(--wpds-colors-green100);
}

.fill-green {
  fill: var(--wpds-colors-green100);
}

.hover-fill-green:hover {
  fill: var(--wpds-colors-green100);
}

.hover-green:hover {
  color: var(--wpds-colors-green100);
}

.hover-bg-green:hover {
  background-color: var(--wpds-colors-green100);
}

.focus-bg-green:focus {
  background-color: var(--wpds-colors-green100);
}

.focus-bc-green:focus {
  border-color: var(--wpds-colors-green100);
}


.bg-green-dark {
  background-color: var(--wpds-colors-green60);
}

.green-dark {
  color: var(--wpds-colors-green60);
}

.bc-green-dark {
  border-color: var(--wpds-colors-green60);
}

.fill-green-dark {
  fill: var(--wpds-colors-green60);
}

.hover-fill-green-dark:hover {
  fill: var(--wpds-colors-green60);
}

.hover-green-dark:hover {
  color: var(--wpds-colors-green60);
}

.hover-bg-green-dark:hover {
  background-color: var(--wpds-colors-green60);
}

.focus-bg-green-dark:focus {
  background-color: var(--wpds-colors-green60);
}

.focus-bc-green-dark:focus {
  border-color: var(--wpds-colors-green60);
}


.bg-green-bright {
  background-color: var(--wpds-colors-green400);
}

.green-bright {
  color: var(--wpds-colors-green400);
}

.bc-green-bright {
  border-color: var(--wpds-colors-green400);
}

.fill-green-bright {
  fill: var(--wpds-colors-green400);
}

.hover-fill-green-bright:hover {
  fill: var(--wpds-colors-green400);
}

.hover-green-bright:hover {
  color: var(--wpds-colors-green400);
}

.hover-bg-green-bright:hover {
  background-color: var(--wpds-colors-green400);
}

.focus-bg-green-bright:focus {
  background-color: var(--wpds-colors-green400);
}

.focus-bc-green-bright:focus {
  border-color: var(--wpds-colors-green400);
}


.bg-green-pale {
  background-color: var(--wpds-colors-green600);
}

.green-pale {
  color: var(--wpds-colors-green600);
}

.bc-green-pale {
  border-color: var(--wpds-colors-green600);
}

.fill-green-pale {
  fill: var(--wpds-colors-green600);
}

.hover-fill-green-pale:hover {
  fill: var(--wpds-colors-green600);
}

.hover-green-pale:hover {
  color: var(--wpds-colors-green600);
}

.hover-bg-green-pale:hover {
  background-color: var(--wpds-colors-green600);
}

.focus-bg-green-pale:focus {
  background-color: var(--wpds-colors-green600);
}

.focus-bc-green-pale:focus {
  border-color: var(--wpds-colors-green600);
}

/* The default opinion brand color. */
.bg-gold {
  background-color: var(--wpds-colors-gold60);
}

.gold {
  color: var(--wpds-colors-gold60);
}

.bc-gold {
  border-color: var(--wpds-colors-gold60);
}

.fill-gold {
  fill: var(--wpds-colors-gold60);
}

.hover-fill-gold:hover {
  fill: var(--wpds-colors-gold60);
}

.hover-gold:hover {
  color: var(--wpds-colors-gold60);
}

.hover-bg-gold:hover {
  background-color: var(--wpds-colors-gold60);
}

.focus-bg-gold:focus {
  background-color: var(--wpds-colors-gold60);
}

.focus-bc-gold:focus {
  border-color: var(--wpds-colors-gold60);
}

/* The opinion color used for hover states and text on a dark background. The hover state on opinion gold bright should be opinion gold normal. */
.bg-gold-bright {
  background-color: var(--wpds-colors-gold200);
}

.gold-bright {
  color: var(--wpds-colors-gold200);
}

.bc-gold-bright {
  border-color: var(--wpds-colors-gold200);
}

.fill-gold-bright {
  fill: var(--wpds-colors-gold200);
}

.hover-fill-gold-bright:hover {
  fill: var(--wpds-colors-gold200);
}

.hover-gold-bright:hover {
  color: var(--wpds-colors-gold200);
}

.hover-bg-gold-bright:hover {
  background-color: var(--wpds-colors-gold200);
}

.focus-bg-gold-bright:focus {
  background-color: var(--wpds-colors-gold200);
}

.focus-bc-gold-bright:focus {
  border-color: var(--wpds-colors-gold200);
}


.bg-gold-dark {
  background-color: var(--wpds-colors-gold40);
}

.gold-dark {
  color: var(--wpds-colors-gold40);
}

.bc-gold-dark {
  border-color: var(--wpds-colors-gold40);
}

.fill-gold-dark {
  fill: var(--wpds-colors-gold40);
}

.hover-fill-gold-dark:hover {
  fill: var(--wpds-colors-gold40);
}

.hover-gold-dark:hover {
  color: var(--wpds-colors-gold40);
}

.hover-bg-gold-dark:hover {
  background-color: var(--wpds-colors-gold40);
}

.focus-bg-gold-dark:focus {
  background-color: var(--wpds-colors-gold40);
}

.focus-bc-gold-dark:focus {
  border-color: var(--wpds-colors-gold40);
}


.subs-theme.bg-blue {
  background-color: var(--wpds-colors-cta);
}

.subs-theme.blue {
  color: var(--wpds-colors-cta);
}

.subs-theme.bc-blue {
  border-color: var(--wpds-colors-cta);
}

.subs-theme.fill-blue {
  fill: var(--wpds-colors-cta);
}

.subs-theme.hover-fill-blue:hover {
  fill: var(--wpds-colors-cta);
}

.subs-theme.hover-blue:hover {
  color: var(--wpds-colors-cta);
}

.subs-theme.hover-bg-blue:hover {
  background-color: var(--wpds-colors-cta);
}

.subs-theme.focus-bg-blue:focus {
  background-color: var(--wpds-colors-cta);
}

.subs-theme.focus-bc-blue:focus {
  border-color: var(--wpds-colors-cta);
}


.subs-theme.bg-blue-dark {
  background-color: var(--wpds-colors-blue60);
}

.subs-theme.blue-dark {
  color: var(--wpds-colors-blue60);
}

.subs-theme.bc-blue-dark {
  border-color: var(--wpds-colors-blue60);
}

.subs-theme.fill-blue-dark {
  fill: var(--wpds-colors-blue60);
}

.subs-theme.hover-fill-blue-dark:hover {
  fill: var(--wpds-colors-blue60);
}

.subs-theme.hover-blue-dark:hover {
  color: var(--wpds-colors-blue60);
}

.subs-theme.hover-bg-blue-dark:hover {
  background-color: var(--wpds-colors-blue60);
}

.subs-theme.focus-bg-blue-dark:focus {
  background-color: var(--wpds-colors-blue60);
}

.subs-theme.focus-bc-blue-dark:focus {
  border-color: var(--wpds-colors-blue60);
}


.subs-theme.bg-blue-bright {
  background-color: var(--wpds-colors-blue300);
}

.subs-theme.blue-bright {
  color: var(--wpds-colors-blue300);
}

.subs-theme.bc-blue-bright {
  border-color: var(--wpds-colors-blue300);
}

.subs-theme.fill-blue-bright {
  fill: var(--wpds-colors-blue300);
}

.subs-theme.hover-fill-blue-bright:hover {
  fill: var(--wpds-colors-blue300);
}

.subs-theme.hover-blue-bright:hover {
  color: var(--wpds-colors-blue300);
}

.subs-theme.hover-bg-blue-bright:hover {
  background-color: var(--wpds-colors-blue300);
}

.subs-theme.focus-bg-blue-bright:focus {
  background-color: var(--wpds-colors-blue300);
}

.subs-theme.focus-bc-blue-bright:focus {
  border-color: var(--wpds-colors-blue300);
}


.subs-theme.bg-blue-pale {
  background-color: var(--wpds-colors-blue600);
}

.subs-theme.blue-pale {
  color: var(--wpds-colors-blue600);
}

.subs-theme.bc-blue-pale {
  border-color: var(--wpds-colors-blue600);
}

.subs-theme.fill-blue-pale {
  fill: var(--wpds-colors-blue600);
}

.subs-theme.hover-fill-blue-pale:hover {
  fill: var(--wpds-colors-blue600);
}

.subs-theme.hover-blue-pale:hover {
  color: var(--wpds-colors-blue600);
}

.subs-theme.hover-bg-blue-pale:hover {
  background-color: var(--wpds-colors-blue600);
}

.subs-theme.focus-bg-blue-pale:focus {
  background-color: var(--wpds-colors-blue600);
}

.subs-theme.focus-bc-blue-pale:focus {
  border-color: var(--wpds-colors-blue600);
}


.subs-theme.bg-pink {
  background-color: #b0578c;
}

.subs-theme.pink {
  color: #b0578c;
}

.subs-theme.bc-pink {
  border-color: #b0578c;
}

.subs-theme.fill-pink {
  fill: #b0578c;
}

.subs-theme.hover-fill-pink:hover {
  fill: #b0578c;
}

.subs-theme.hover-pink:hover {
  color: #b0578c;
}

.subs-theme.hover-bg-pink:hover {
  background-color: #b0578c;
}

.subs-theme.focus-bg-pink:focus {
  background-color: #b0578c;
}

.subs-theme.focus-bc-pink:focus {
  border-color: #b0578c;
}


.subs-theme.bg-pink-dark {
  background-color: #853b67;
}

.subs-theme.pink-dark {
  color: #853b67;
}

.subs-theme.bc-pink-dark {
  border-color: #853b67;
}

.subs-theme.fill-pink-dark {
  fill: #853b67;
}

.subs-theme.hover-fill-pink-dark:hover {
  fill: #853b67;
}

.subs-theme.hover-pink-dark:hover {
  color: #853b67;
}

.subs-theme.hover-bg-pink-dark:hover {
  background-color: #853b67;
}

.subs-theme.focus-bg-pink-dark:focus {
  background-color: #853b67;
}

.subs-theme.focus-bc-pink-dark:focus {
  border-color: #853b67;
}


.subs-theme.bg-pink-bright {
  background-color: #e3a7cb;
}

.subs-theme.pink-bright {
  color: #e3a7cb;
}

.subs-theme.bc-pink-bright {
  border-color: #e3a7cb;
}

.subs-theme.fill-pink-bright {
  fill: #e3a7cb;
}

.subs-theme.hover-fill-pink-bright:hover {
  fill: #e3a7cb;
}

.subs-theme.hover-pink-bright:hover {
  color: #e3a7cb;
}

.subs-theme.hover-bg-pink-bright:hover {
  background-color: #e3a7cb;
}

.subs-theme.focus-bg-pink-bright:focus {
  background-color: #e3a7cb;
}

.subs-theme.focus-bc-pink-bright:focus {
  border-color: #e3a7cb;
}


.subs-theme.bg-pink-pale {
  background-color: #fef4fa;
}

.subs-theme.pink-pale {
  color: #fef4fa;
}

.subs-theme.bc-pink-pale {
  border-color: #fef4fa;
}

.subs-theme.fill-pink-pale {
  fill: #fef4fa;
}

.subs-theme.hover-fill-pink-pale:hover {
  fill: #fef4fa;
}

.subs-theme.hover-pink-pale:hover {
  color: #fef4fa;
}

.subs-theme.hover-bg-pink-pale:hover {
  background-color: #fef4fa;
}

.subs-theme.focus-bg-pink-pale:focus {
  background-color: #fef4fa;
}

.subs-theme.focus-bc-pink-pale:focus {
  border-color: #fef4fa;
}


.subs-theme.bg-green {
  background-color: #1c7c4e;
}

.subs-theme.green {
  color: #1c7c4e;
}

.subs-theme.bc-green {
  border-color: #1c7c4e;
}

.subs-theme.fill-green {
  fill: #1c7c4e;
}

.subs-theme.hover-fill-green:hover {
  fill: #1c7c4e;
}

.subs-theme.hover-green:hover {
  color: #1c7c4e;
}

.subs-theme.hover-bg-green:hover {
  background-color: #1c7c4e;
}

.subs-theme.focus-bg-green:focus {
  background-color: #1c7c4e;
}

.subs-theme.focus-bc-green:focus {
  border-color: #1c7c4e;
}


.subs-theme.bg-green-dark {
  background-color: #0b5733;
}

.subs-theme.green-dark {
  color: #0b5733;
}

.subs-theme.bc-green-dark {
  border-color: #0b5733;
}

.subs-theme.fill-green-dark {
  fill: #0b5733;
}

.subs-theme.hover-fill-green-dark:hover {
  fill: #0b5733;
}

.subs-theme.hover-green-dark:hover {
  color: #0b5733;
}

.subs-theme.hover-bg-green-dark:hover {
  background-color: #0b5733;
}

.subs-theme.focus-bg-green-dark:focus {
  background-color: #0b5733;
}

.subs-theme.focus-bc-green-dark:focus {
  border-color: #0b5733;
}


.subs-theme.bg-green-bright {
  background-color: #439e73;
}

.subs-theme.green-bright {
  color: #439e73;
}

.subs-theme.bc-green-bright {
  border-color: #439e73;
}

.subs-theme.fill-green-bright {
  fill: #439e73;
}

.subs-theme.hover-fill-green-bright:hover {
  fill: #439e73;
}

.subs-theme.hover-green-bright:hover {
  color: #439e73;
}

.subs-theme.hover-bg-green-bright:hover {
  background-color: #439e73;
}

.subs-theme.focus-bg-green-bright:focus {
  background-color: #439e73;
}

.subs-theme.focus-bc-green-bright:focus {
  border-color: #439e73;
}


.subs-theme.bg-green-pale {
  background-color: #eff5f2;
}

.subs-theme.green-pale {
  color: #eff5f2;
}

.subs-theme.bc-green-pale {
  border-color: #eff5f2;
}

.subs-theme.fill-green-pale {
  fill: #eff5f2;
}

.subs-theme.hover-fill-green-pale:hover {
  fill: #eff5f2;
}

.subs-theme.hover-green-pale:hover {
  color: #eff5f2;
}

.subs-theme.hover-bg-green-pale:hover {
  background-color: #eff5f2;
}

.subs-theme.focus-bg-green-pale:focus {
  background-color: #eff5f2;
}

.subs-theme.focus-bc-green-pale:focus {
  border-color: #eff5f2;
}


.subs-theme.bg-navy {
  background-color: #0c198a;
}

.subs-theme.navy {
  color: #0c198a;
}

.subs-theme.bc-navy {
  border-color: #0c198a;
}

.subs-theme.fill-navy {
  fill: #0c198a;
}

.subs-theme.hover-fill-navy:hover {
  fill: #0c198a;
}

.subs-theme.hover-navy:hover {
  color: #0c198a;
}

.subs-theme.hover-bg-navy:hover {
  background-color: #0c198a;
}

.subs-theme.focus-bg-navy:focus {
  background-color: #0c198a;
}

.subs-theme.focus-bc-navy:focus {
  border-color: #0c198a;
}


.subs-theme.bg-navy-dark {
  background-color: #0e1555;
}

.subs-theme.navy-dark {
  color: #0e1555;
}

.subs-theme.bc-navy-dark {
  border-color: #0e1555;
}

.subs-theme.fill-navy-dark {
  fill: #0e1555;
}

.subs-theme.hover-fill-navy-dark:hover {
  fill: #0e1555;
}

.subs-theme.hover-navy-dark:hover {
  color: #0e1555;
}

.subs-theme.hover-bg-navy-dark:hover {
  background-color: #0e1555;
}

.subs-theme.focus-bg-navy-dark:focus {
  background-color: #0e1555;
}

.subs-theme.focus-bc-navy-dark:focus {
  border-color: #0e1555;
}


.subs-theme.bg-navy-bright {
  background-color: #3846c1;
}

.subs-theme.navy-bright {
  color: #3846c1;
}

.subs-theme.bc-navy-bright {
  border-color: #3846c1;
}

.subs-theme.fill-navy-bright {
  fill: #3846c1;
}

.subs-theme.hover-fill-navy-bright:hover {
  fill: #3846c1;
}

.subs-theme.hover-navy-bright:hover {
  color: #3846c1;
}

.subs-theme.hover-bg-navy-bright:hover {
  background-color: #3846c1;
}

.subs-theme.focus-bg-navy-bright:focus {
  background-color: #3846c1;
}

.subs-theme.focus-bc-navy-bright:focus {
  border-color: #3846c1;
}
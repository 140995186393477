/*
    Add `"--nested-focus-within-color": "var(--color-brand-blue-normal)"`
    to style tag of element that you wish to change text color when parent is focus-within
*/

:root {
  --nested-focus-within-color: var(--color-brand-blue-normal);
}

.focus-within:focus-within .nested-focus-within-color {
  color: var(--nested-focus-within-color);
}

.focus-within:focus-within .focused-db {
  display: block;
}

.focus-within:focus-within .focused-pt-sm {
  padding-top: var(--sm);
}

/* Autogenerated by build-typography-classes.js*/
.lh-0 {
  line-height: 0;
}
.lh-1 {
  line-height: 1;
}
.lh-sm {
  line-height: 1.1;
}
.lh-md {
  line-height: 1.25;
}
.lh-lg,
.lh3 {
  line-height: 1.5;
}
.lh-lg2 {
  line-height: 1.6;
}
.lh-default {
  line-height: 1.75;
}
.lh-xl {
  line-height: 2;
}
.lh-xxl {
  line-height: 2.4;
}

.scrollbar-width-none {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-width-none::-webkit-scrollbar {
  width: 0;
}

.select-none {
  user-select: none;
}
.outline-none {
  outline: none;
}
.appearance-none {
  appearance: none;
}
.webkit-search-cancel-button-appearance-none::-webkit-search-cancel-button {
  appearance: none;
}
.default {
  cursor: default;
}
.pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
}
.pointer-events-none {
  pointer-events: none;
}
.dn,
.hover-dn:hover {
  display: none;
}
.db {
  display: block;
}
.dib,
.hover-dib:hover {
  display: inline-block;
}
.dit {
  display: inline-table;
}
.hover-parent:hover .hover-child-db {
  display: block;
}
.hover-parent:hover .hover-child-dib {
  display: inline-block;
}
.hover-parent:hover .hover-child-dn {
  display: none;
}
.hover-parent:hover .hover-child-o-1 {
  opacity: 1;
}
.empty-dn:empty {
  display: none;
}

.fill-dodgerblue {
  fill: #2c85fc;
}
.fill-success-green {
  fill: var(--color-ui-green-normal);
}
.fill-inherit {
  fill: inherit;
}
.table {
  display: table;
}
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-1 {
  flex: 1;
}
.flex-0 {
  flex: 0;
}
.flex-none {
  flex: none;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-wrap {
  flex-wrap: wrap;
}
.inline-flex {
  display: inline-flex;
}
.inline-flex-column {
  flex-direction: column;
}
.inline-flex-row {
  flex-direction: row;
}
.inline-flex-1 {
  flex: 1;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}
.items-center {
  align-items: center;
}
.self-center {
  align-self: center;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-start,
.justify-flex-start {
  justify-content: flex-start;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.fn {
  float: none;
}
.cb {
  clear: both;
}
.clearfix:after {
  display: block;
  content: "";
  clear: both;
}

.of-contain {
  object-fit: contain;
}
.of-cover {
  object-fit: cover;
}
.o-0 {
  opacity: 0;
}
.o-15 {
  opacity: 0.15;
}
.o-50 {
  opacity: 0.5;
}
.o-75 {
  opacity: 0.75;
}
.o-100,
.o-1 {
  opacity: 1;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.overflow-xscroll {
  overflow-x: scroll;
  scrollbar-width: none;
}
.overflow-xscroll::-webkit-scrollbar {
  display: none;
}
.overflow-yscroll {
  overflow-y: scroll;
  scrollbar-width: none;
}
.overflow-yscroll::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .overflow-visible-lg {
    overflow: visible;
  }
}
.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.nowrap {
  white-space: nowrap;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.right-0 {
  right: 0;
}
.left-0 {
  left: 0;
}
@media only screen and (max-width: 767px) {
  .left-0-s {
    left: 0;
  }
  .right-0-s {
    right: 0;
  }
}
.top-50 {
  top: 50%;
}
.top-0 {
  top: 0;
}
.top-sm {
  top: var(--size-spacing-sm);
}
.right-sm {
  right: var(--size-spacing-sm);
}
.bottom-0 {
  bottom: 0;
}
.va-t {
  vertical-align: top;
}
.va-m {
  vertical-align: middle;
}
.va-sub {
  vertical-align: sub;
}
.va-b {
  vertical-align: bottom;
}
.hidden {
  visibility: hidden;
}
.visible-on-hover:hover {
  visibility: visible;
}
.visible {
  visibility: visible;
}
.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.z-6 {
  z-index: 6;
}
.z-7 {
  z-index: 7;
}
.link-hover-underline:hover {
  text-decoration: underline;
}
.hover-inherit:hover {
  color: inherit;
}

.list-unstyled {
  list-style-type: none;
}

.border-box {
  box-sizing: border-box;
}
.content-box {
  box-sizing: content-box;
}

@media only screen and (min-width: 768px) {
  .flex-row-reverse-ns {
    flex-direction: row-reverse;
  }
  .flex-column-reverse-ns {
    flex-direction: column-reverse;
  }
  .dn-ns {
    display: none;
  }
  .dib-ns {
    display: inline-block;
  }
  .db-ns {
    display: block;
  }
  .flex-ns {
    display: flex;
  }
  .flex-ns-row {
    flex-direction: row;
  }
  .inline-flex-ns {
    display: inline-flex;
  }
  .inline-flex-ns-column {
    flex-direction: column;
  }
  .inline-flex-ns-row {
    flex-direction: row;
  }
  .inline-flex-ns-1 {
    flex: 1;
  }
  .items-ns-start {
    align-items: flex-start;
  }
  .items-ns-center {
    align-items: center;
  }
  .items-ns-end {
    align-items: flex-end;
  }
  .justify-center-ns {
    justify-content: center;
  }
  .justify-between-ns {
    justify-content: space-between;
  }
  .fl-ns {
    float: left;
  }
  .fr-ns {
    float: right;
  }
  .overflow-hidden-ns {
    overflow: hidden;
  }
  .absolute-ns {
    position: absolute;
  }
  .grid-ns {
    display: grid;
  }
  .fixed-ns {
    position: fixed;
  }
  .sticky-ns {
    position: sticky;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .dn-m {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .dn-l {
    display: none;
  }
  .grid-l {
    display: grid;
  }
  .dib-l {
    display: inline-block;
  }
  .db-l {
    display: block;
  }
  .flex-l {
    display: flex;
  }
  .mt-md-l {
    margin-top: 24px;
  }
  .o-0-l {
    opacity: 0;
  }
  .o-100-l {
    opacity: 1;
  }
  .visible-l {
    visibility: visible;
  }
  .hidden-l {
    visibility: hidden;
  }
  .flex-l {
    display: flex;
  }
}
@media (hover: hover) {
  .dn-h {
    display: none;
  }
}

.touch-none {
  touch-action: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
/** sticky bar slide in */

.focus-highlight:focus {
  outline: auto 1px Highlight;
  outline: auto 1px -webkit-focus-ring-color;
}

.will-change-height {
  will-change: height;
}

.bg-gray-darkest-alpha-25 {
  background-color: var(--gray-darkest-alpha-25);
}

.snap-start {
  scroll-snap-align: start;
}

/** creates a divider line around text:
   * --------- text goes here ----------
   */

.divider::before,
.divider::after {
  flex: 1 auto;
  margin-top: var(--xs);
  height: 1px;
  content: "";
  background: var(--primary-border-color);
}

.divider::before {
  margin-right: var(--xs);
}

.divider::after {
  margin-left: var(--xs);
}

.dn-if-empty:empty {
  display: none;
}

.disabled\:dn:disabled {
  display: none;
}

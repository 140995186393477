/** These will be deprecated soon */
.shadow {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
}
.shadow-light {
  box-shadow: 0 1px 1px 0 rgba(102, 102, 102, 0.1);
}
.inset-shadow {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}
.no-shadow {
  box-shadow: none;
}

.drop-shadow {
  filter: drop-shadow(var(--gray-light) 0 2px 10px);
}

.drop-shadow-dark {
  filter: drop-shadow(var(--gray-dark) 0 2px 10px);
}

@media only screen and (min-width: 768px) {
  .shadow-light-ns {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  }
}

/* Autogenerated by build-typography-classes.js*/

.font-0 {
  font-size: 0;
}
.font-xxxxs {
  font-size: 0.75rem;
}
.font-xxxs {
  font-size: 0.875rem;
}
.font-xxs {
  font-size: 1rem;
}
.font-xs {
  font-size: 1.125rem;
}
.font-sm {
  font-size: 1.25rem;
}
.font-copy {
  font-size: 1.125rem;
}
.font-md {
  font-size: 1.5rem;
}
.font-md2 {
  font-size: 1.75rem;
}
.font-md3 {
  font-size: 2rem;
}
.font-lg {
  font-size: 2.5rem;
}
.font-xl {
  font-size: 3.5rem;
}
.font-xxl {
  font-size: 4.5rem;
}
.font-xxxl {
  font-size: 5rem;
}

/* tablet and up breakpoint */
@media only screen and (min-width: 768px) {
  .font-xxxs-ns {
    font-size: 0.875rem;
  }
  .font-xxs-ns {
    font-size: 1rem;
  }
  .font-xs-ns {
    font-size: 1.125rem;
  }
}

/* tablet breakpoint */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .font-copy {
    font-size: 1.25rem;
  }
}
/* desktop breakpoint */
@media only screen and (min-width: 1024px) {
  .font-xxxs-l {
    font-size: 0.875rem;
  }
  .font-md-l {
    font-size: 1.5rem;
  }
  .font-copy {
    font-size: 1.25rem;
  }
}

/* mobile breakpoint */
@media only screen and (max-width: 767px) {
  .font-sm-md3 {
    font-size: var(--font-size-medium-variant-three);
  }
  .font-xxs-s {
    font-size: var(--font-size-xxs);
  }
}

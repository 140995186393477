.w-0 {
  width: 0;
}

.w-xxxs {
  width: 8px;
}

.w-xxs {
  width: 16px;
}

/* t-shirt sizes do not work */
.w-20 {
  width: 20px;
}

.w-xs,
.w-24 {
  width: 24px;
}

.w-sm {
  width: 32px;
}

.w-md {
  width: 40px;
}

.w-lg {
  width: 44px;
}

.w-xl {
  width: 48px;
}

.w-xxl {
  width: 56px;
}

.w-33 {
  width: 33%;
}

.w-50 {
  width: 50%;
}

.w-66 {
  width: 66%;
}

.w-100 {
  width: 100%;
}

.w-300 {
  width: 300px;
}

.mw-99 {
  max-width: 99%;
}

.mw-100 {
  max-width: 100%;
}

.mw-144 {
  max-width: 144px;
}

.mw-200 {
  max-width: 200px;
}

.mw-300 {
  max-width: 300px;
}

.mw-600 {
  max-width: 600px;
}

.minw-200 {
  min-width: 200px;
}

.mw-420 {
  max-width: 420px;
}

.mw-vw-100 {
  max-width: 100vw;
}

/* idk doesn't fit our spacing scale */
.min-w-btn {
  min-width: 120px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.minw-max {
  min-width: max-content;
}

@media only screen and (min-width: 1024px) {
  .w-0-l {
    width: 0;
  }
  .w-100-l {
    width: 100%;
  }
  .mw-0-lg {
    max-width: 0;
  }
  .mw-fit-content-lg {
    max-width: fit-content;
  }
}

@media only screen and (max-width: 1023px) {
  .mw-unset-nl {
    max-width: unset;
  }
}

@media only screen and (max-width: 767px) {
  .w-33-s {
    width: 33%;
  }
  .w-50-s {
    width: 50%;
  }
  .w-66-s {
    width: 66%;
  }
  .w-100-s {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .w-50-ns {
    width: 50%;
  }
  .w-33-ns {
    width: 33%;
  }
  .w-66-ns {
    width: 66%;
  }
  .w-auto-ns {
    width: auto;
  }
  .mw-unset-ns {
    max-width: unset;
  }
  .mw-300-ns {
    max-width: 300px;
  }
  .minw-200-ns {
    min-width: 200px;
  }
}

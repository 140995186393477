/* 

  # Unstable Theme Tachyons
  
  Use at your own risk. They may be rewritten or removed at later date.

*/

:root {
  --primary-background: var(--color-ui-black);
  --secondary-background: var(--color-ui-gray-darkest);
  --primary-fill: var(--color-ui-white);
  --secondary-text: var(--color-ui-gray-light);
  --link-color: var(--color-brand-blue-normal);
}

.unstable-bg-primary {
  background-color: var(--primary-background);
}

.unstable-bg-secondary {
  background-color: var(--secondary-background);
}

.unstable-secondary {
  color: var(--secondary-text);
}

.unstable-fill-path-secondary path {
  fill: var(--primary-fill);
}

/* prettier-ignore */
    /* Autogenerated by build/tachyons.js */


      /** Shadow 1 - Card shadow */
      .shadow-1 {
            box-shadow: var(--shadow-card);
        }


      /** Shadow 2 - Extra small */
      .shadow-2 {
            box-shadow: var(--shadow-xs);
        }


      /** Shadows 3 - Small */
      .shadow-3 {
            box-shadow: var(--shadow-sm);
        }


      /** Shadows 4 - Medium */
      .shadow-4 {
            box-shadow: var(--shadow-md);
        }


      /** Shadows 5 - Large */
      .shadow-5 {
            box-shadow: var(--shadow-lg);
        }


      /** Shadows 6 - Extra large */
      .shadow-6 {
            box-shadow: var(--shadow-xl);
        }
/* Autogenerated by build-typography-classes.js*/

.font--article-body {
  font-family: var(--wpds-fonts-body);
  line-height: 1.6;
}

.font--headline {
  font-family: var(--wpds-fonts-headline);
  line-height: 1.1;
}
.font--magazine-headline {
  font-family: var(--wpds-fonts-magazine);
  line-height: 1.1;
}
.font--meta-text {
  font-family: var(--wpds-fonts-meta);
  line-height: 1.25;
}
.font--subhead {
  font-family: var(--wpds-fonts-subhead);
  line-height: 1.25;
}
.font--body {
  font-family: var(--wpds-fonts-body);
  line-height: 1.75;
}
.font-sans-serif {
  font-family: var(--wpds-fonts-meta);
}

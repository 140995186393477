.h-0 {
  height: 0;
}
.h-100 {
  height: 100%;
}

.h-auto,
.h-initial {
  height: auto;
}

.h-xxxs {
  height: 8px;
}

.h-xxs {
  height: 16px;
}

/* t-shirt sizes do not work */
.h-20 {
  height: 20px;
}

.h-xs,
.h-24 {
  height: 24px;
}

.h-sm {
  height: 32px;
}

.h-md {
  height: 40px;
}

.h-lg {
  height: 44px;
}

.h-xl {
  height: 48px;
}

.h-xxl {
  height: 56px;
}

.mh-vh-100 {
  max-height: 100vh;
}
